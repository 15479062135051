import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { StyledLink, StyledA, CommonArrowButton, ButtonArrow } from "./common";
import { Link } from "gatsby";
import buttonArrow from "../images/arrow-white.svg";
import segmentlyLogo from "../images/segmently-logo.svg";

const TimeTrackingHomeSection = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.segmently
      ? `650px minmax(300px, 700px)`
      : `650px minmax(300px, 750px)`};
  grid-column-gap: 5%;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems || `center`};
  padding: ${(props) => (props.segmently ? `40px 9% 20px` : `20px 9%`)};
  @media (max-width: 1500px) {
    grid-template-columns: 540px 1fr;
    justify-content: center;
  }
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px 40px;
    align-items: center;
  }
  @media (max-width: 768px) {
    grid-template-columns: 80%;
    grid-row-gap: 40px;
    margin-top: 10px;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    grid-row-gap: 30px;
    margin-top: 0;
    padding: 20px 25px;
  }
`;

const TextSection = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  padding-top: 12%;
  @media (max-width: 1366px) {
    padding-top: 8%;
  }
  @media (max-width: 575px) {
    padding-top: 3%;
  }
`;

const HomeAnimateContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 40px;
`;
const HomeImageFlex = styled.div`
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: ${(props) =>
    props.imageFlex == "a" ? `70%` : `100%`};
`;

const HomeButtonSection = styled.div`
  padding-top: 5vh;
  display: grid;
  justify-content: start;
  @media (max-width: 575px) {
    justify-content: center;
  }
`;
const HomeSubTitleSection = styled.div`
  display: grid;
  grid-template-columns: 4px auto;
  grid-column-gap: 20px;
  margin-top: 6vh;
  max-width: 500px;
  @media (max-width: 575px) {
    margin-top: 4vh;
  }
`;
const HomeSubTitleBar = styled.div`
  background-color: ${({ barColor }) => barColor || `#f77b63`};
  border-radius: 2px;
`;
const IconAppSection = styled.div`
  display: grid;
  grid-template-columns: 250px;
  justify-content: start;
  @media (max-width: 575px) {
    grid-template-columns: 220px;
  }
`;
const IconAppText = styled.p`
  font-size: 33px;
  color: #1f2939;
  line-height: 1.2;
`;
const CouraselDots = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`;
const CouraselDot = styled.div`
  width: ${(props) => (props.selector === props.index ? `22px` : `7px`)};
  height: 7px;
  background-color: ${(props) =>
    props.selector === props.index ? `#4D7CFF` : `#c2c8ce`};
  border-radius: ${(props) =>
    props.selector === props.index ? `30px` : `50%`};
  transition: all 0.4s ease;
`;
const Courasel = (props) => {
  const [selector, setSelector] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selector < 2) {
        setSelector((selector) => selector + 1);
      } else {
        setSelector(0);
      }
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [selector]);

  return (
    <>
      {props.imageArray && props.imageArray[selector] && (
        <HomeImageFlex>
          <img
            src={props.imageArray[selector].src}
            alt={props.imageArray[selector].alt}
            width="100%"
          />
        </HomeImageFlex>
      )}
      <CouraselDots>
        <CouraselDot selector={selector} index={0} />
        <CouraselDot selector={selector} index={1} />
        <CouraselDot selector={selector} index={2} />
      </CouraselDots>
    </>
  );
};

export const Home = (props) => {
  return (
    <TimeTrackingHomeSection
      segmently={props.segmently}
      alignItems={props.alignItems}
    >
      <TextSection style={{ justifyContent: `start` }}>
        <div>
          {(props.appLogo && props.segmently) ? (
            <IconAppSection>
              <img
                src={props.appLogo}
                alt={props.logoText ? props.logoText : `logo`}
                width="100%"
              />
            </IconAppSection>
          ) : props.segmently  ? (
            <IconAppSection>
              <img src={segmentlyLogo} alt="segmently logo" width="100%" />
            </IconAppSection>
          ) : (
            <> </>
          )}
          <h1>{props.homeH1}</h1>
          {props.homeText !== "" && (
            <HomeSubTitleSection>
              <HomeSubTitleBar barColor={props.barColor} />
              <p>{props.homeText}</p>
            </HomeSubTitleSection>
          )}
          <HomeButtonSection>
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={props.buttonLink || ``}
            >
              <CommonArrowButton>
                <div>{props.ButtonText}</div>
                <ButtonArrow>
                  <img
                    src={buttonArrow}
                    alt="arrow"
                    width="100%"
                    style={{ paddingLeft: `10px` }}
                  />
                </ButtonArrow>
              </CommonArrowButton>
            </StyledA>
          </HomeButtonSection>
        </div>
      </TextSection>
      <HomeAnimateContainer>
        {props.segmently ? (
          <Courasel imageArray={props.imageArray} />
        ) : (
          <HomeImageFlex>{props.image}</HomeImageFlex>
        )}
      </HomeAnimateContainer>
    </TimeTrackingHomeSection>
  );
};
